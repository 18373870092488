section.cart-section {
    padding: 40px 0;
    background-color: transparent;
}
section.cart-section .cart-box {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 30px;
}
section.cart-section .cart-box table {
    width: 100%;
}
.sidebar .widget {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 1.75rem;
    margin-bottom: 1.75rem;
}
.sidebar .widget h2 {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid #f4f4f4;
    position: relative;
}
.sidebar .cart-widget table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 30px;
}
.sidebar .cart-widget a {
    color: #fff;
    text-align: center;
    border: 0;
    padding: 10px 22px;
    width: 100%;
    vertical-align: bottom;
    font-weight: 400;
    font-size: 16px;
    text-shadow: none;
    display: inline-block;
    background-color: #c5892b;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    cursor: pointer;
}
section.cart-section .cart-box table tr.coupon-line button {
    float: right;
    color: #3c719a;
    font-size: 15px;
    padding: 10px 25px;
    border: 1px solid #3c719a;
    font-weight: 400;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    border-radius: 24px;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    margin: 0;
    outline: none;
}
section.cart-section .cart-box table tr td input[type="text"] {
    width: 200px;
    padding: 12px 20px;
    border: 1px solid #dcdcdc;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    outline: none;
}
section.cart-section .cart-box table tr.coupon-line td {
    padding: 30px 0;
}
section.cart-section .cart-box table tr td {
    /* padding: 30px 13px; */
    font-size: 15px;
    color: #7d7e7f;
}
section.cart-section .cart-box table tr.coupon-line {
    border-bottom: none;
}
section.cart-section .cart-box table tr td {
    padding: 30px 13px;
    font-size: 15px;
    color: #7d7e7f;
}
section.cart-section .cart-box table tr {
    border-bottom: 1px solid #eaeaea;
}
section.cart-section .cart-box table tr td {
    padding: 30px 13px;
    font-size: 15px;
    color: #7d7e7f;
}
section.cart-section .cart-box table tr td {
    padding: 30px 13px;
    font-size: 15px;
    color: #7d7e7f;
}
section.cart-section .cart-box table tr td input[type="number"] {
    max-width: 60px;
    border: 0;
    padding: 10px 12px;
    border-bottom: 2px solid #e5e5e5;
    outline: none;
}